// HOME PAGE ANIMATIONS
/* global $, scrollMonitor, TimelineLite, Power3, parallaxIt */

$(document).ready(function () {
  // Navbar
  $('.navigation').headroom({
    // 'offset': ($(window).height() * 95 / 100).toFixed() - 200,
    'offset': 20,
    'tolerance': {
      'up': 0,
      'down': 0
    }
  })

  $('.navigation a').click(function (event) {
    event.preventDefault()
    var hash = this.hash
    $('html, body').animate({
      scrollTop: $(hash).offset().top
    }, 600, function () {
      // window.location.hash = hash
    })
    // $('.navbar-collapse').collapse('hide')
  })

  // Bottom links
  $('section.quick-links').headroom({
    'offset': ($(window).height() * 95 / 100).toFixed(),
    'tolerance': {
      'up': 0,
      'down': 0
    }
  })

  // Scrollspy setup
  $('body').scrollspy({
    target: '.navigation',
    offset: 100
  })

  // Hero Slider Setup
  $('.hero-slider').slick({
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
    cssEase: 'linear',
    prevArrow: 'section.hero .slider-prev',
    nextArrow: 'section.hero .slider-next'
  })

  // Company Slider Setup
  $('.company-slider').slick({
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1500,
    fade: true,
    cssEase: 'linear',
    prevArrow: 'section.company .slider-prev',
    nextArrow: 'section.company .slider-next',
    responsive: [
      {
        breakpoint: 479,
        settings: {
          dots: true,
          arrows: false
        }
      }
    ]
  })

  // Slider Slider Setup
  $('.fullscreen-slider').slick({
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
    cssEase: 'linear',
    prevArrow: 'section.fullscreen .slider-prev',
    nextArrow: 'section.fullscreen .slider-next'
  })
})
