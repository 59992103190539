// CONTACT FORM HERO
/* global $ */

var contactFormHero = $('#contact-form-hero')
var contactFormTriggerHero = $('#contact-form-trigger-hero')
var contactUrlHero = $('#contact-form-hero').attr('action')
var contactErrorHero = $('#contact-form-error-hero')

// Email validation
function validateEmailHero (email) {
  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/
  if (email) {
    return emailReg.test(email)
  }
}

// Validate required fields
function validateFieldsHero () {
  var contactNameHero = $('#form-name-hero').val()
  var contactEmailHero = $('#form-email-hero').val()

  if (contactNameHero !== '' && validateEmailHero(contactEmailHero)) {
    return true
  }
}

// Submit form
contactFormHero.submit(function (e) {
  e.preventDefault()
  contactFormTriggerHero.attr('disabled', 'disabled').addClass('disabled')
  contactErrorHero.html('').hide()

  if (validateFieldsHero()) {
    var contactDataHero = $(this).serialize()

    $.ajax({
      type: 'POST',
      url: contactUrlHero,
      data: contactDataHero,
      dataType: 'json',

      success: function (pResponse) {
        if (pResponse.success) {
          // Shows success message; resets form and reenables button after 3 sec
          contactErrorHero.html(pResponse.message).addClass('success').show()
          console.log('CONTACT - HERO')
          gtag('event', 'conversion', {'send_to': 'AW-632588958/bO1aCKb-pbkCEJ6V0q0C'})
          setTimeout(function () {
            contactErrorHero.html('').hide().removeClass('success error')
            contactFormTriggerHero.removeAttr('disabled').removeClass('disabled')
            contactFormHero.find(':input').not('input[type=button], input[type=submit], input[type=reset]').each(function () {
              $(this).val('')
            })
          }, 3000)
        } else {
          // Shows EMAIL error message; reenables button after 5 sec
          contactErrorHero.html(pResponse.message).addClass('error').show()
          setTimeout(function () {
            contactErrorHero.html('').hide().removeClass('success error')
            contactFormTriggerHero.removeAttr('disabled').removeClass('disabled')
          }, 5000)
        }
      },

      error: function (pResponse) {
        // Shows AJAX error message; reenables button after 5 sec
        contactErrorHero.html('Ocurrió un error al enviar el email, por favor reinténtelo.').addClass('error').show()
        setTimeout(function () {
          contactErrorHero.html('').hide().removeClass('success error')
          contactFormTriggerHero.removeAttr('disabled').removeClass('disabled')
        }, 5000)
      }
    })
  } else {
    // Shows FIELD VALIDATION error message; reenables button after 5 sec
    contactErrorHero.html('Ingrese su nombre y su email para que podamos contactarlo.').addClass('error').show()
    setTimeout(function () {
      contactErrorHero.html('').hide().removeClass('success error')
      contactFormTriggerHero.removeAttr('disabled').removeClass('disabled')
    }, 5000)
  }
})
